<template>
  <div class="work-bar">
    <div class="charts-box">
      <div :id="`myChart-Work-${projectID}`" class="myChart-Work-box">
        <!-- :style="{ width: '455px', height: '300px', margin: '0 auto' }" -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      courseType: {
        type: Number
      },
      projectID: {
        type: Number
      },
    },
    data() {
      return {
        xAxisList: [], //横坐标
        TimesValueList: [], //开课次数value列表
        finishRateList: [] //完课率value列表
      }
    },
    watch: {
      courseType(newVal, oldVal) {
        if (newVal != 0 && newVal != 1) return
        this.getWorkChart();
      }
    },
    methods: {
      async getWorkChart() {
        this.TimesValueList = [];
        this.finishRateList = [];
        this.xAxisList = [];
        let params = {
          projectId: this.projectID,
          type: this.courseType
        }
        let resData = await this.$Api.Home.getHomeWorkChart(params);
        // console.log('作业',resData.data);
        this.$emit('shuttle', {
          workData: resData.data,
          id: this.projectID
        })
        for (const key in resData.data) {
          // console.log(key);
          this.xAxisList.push(key);
          this.TimesValueList.push(resData.data[key].time)
          this.finishRateList.push(resData.data[key].finishRate)
        }
        // console.log(this.xAxisList);
        this.drawBar();
      },
      drawBar() {
        let myChartWork = this.$echarts.init(
          document.getElementById(`myChart-Work-${this.projectID}`)
        );
        window.addEventListener('resize', () => {
          myChartWork.resize()
        })

        myChartWork.setOption({
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999"
              }
            },
            formatter: function (params) {
              return params[0].axisValue + "<br/>" + params[0].seriesName + "：" + params[0].value + "<br/>" +
                params[1].seriesName + "：" + params[1].value + "%";
            }
          },
          legend: {
            data: ["作业次数", "完成率"],
            top: 0
          },
          xAxis: [{
            type: "category",
            data: this.xAxisList,
            axisPointer: {
              type: "shadow"
            },
            axisLabel: {
              color: '#666666',
              interval: 0,
              fontSize: 10,
              rotate: -25,
              margin: 15,
            },
            axisLine: {
              show: false,
            },
            // 刻度线
            axisTick: {
              alignWithLabel: true,
              lineStyle: {
                color: "rgba(166, 166, 166, .32)",
              }
            },
          }],
          yAxis: [{
              type: "value",
              name: "作业次数",
              nameTextStyle: {
                color: "#888888"
              },
              min: 0,
              // max: 100,
              // interval: this.yAxisTimesInterval,
              axisLabel: {
                formatter: "{value}",
                color: "#888888",
                fontSize: "10"
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(166, 166, 166, .32)",
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false
              }
            },
            {
              type: "value",
              name: "完成率",
              nameTextStyle: {
                color: "#888888"
              },
              min: 0,
              max: 100,
              interval: 20,
              axisLabel: {
                formatter: "{value} %",
                color: "#888888",
                fontSize: "10"
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(166, 166, 166, .32)",
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(166, 166, 166, .32)",
                },
              },
            }
          ],
          // dataZoom: [{
          //   show: true,
          //   fillerColor: "rgba(119, 169, 250, 0.4)",
          // }, {
          //   type: 'inside',
          //   start: 100,
          //   end: 70,
          // }],
          dataZoom: [{
            type: 'inside',
            start: 0,
            end: 80,
            maxValueSpan: 6,
            zoomOnMouseWheel: false,
            zoomLock: true,
            filterMode: 'none',
          }],
          series: [{
              name: "作业次数",
              type: "bar",
              data: this.TimesValueList,
              itemStyle: {
                //柱状颜色
                color: "#77A9FA",
                barBorderRadius: [20, 20, 0, 0]
              },
              barMaxWidth: 10

            },
            {
              name: "完成率",
              type: "line",
              yAxisIndex: 1,
              symbol: 'none',
              data: this.finishRateList,
              itemStyle: {
                //线颜色
                color: "#FB5B2A",
              },
            }
          ]
        });

      }
    },
    mounted() {
      this.getWorkChart();
    }
  }
</script>

<style lang="less" scoped>
  .work-bar {
    .myChart-Work-box {
      width: 301px;
      height: 217px;
      margin: 0 auto;
      // background-color: rgba(35, 115, 236, 0.03);

      @media screen and (min-width: 1250px) {
        width: 372px;
        height: 262px;
      }
    }
  }
</style>