<template>
  <!-- 能力图谱 -->
  <div class="ability-radar">
    <div class="chart-box" v-show="abilityRaderData.length > 0 &&  abilityRaderData">
      <div :id="`myChart-Custom-${projectID}`" class="myChart-canvas-box">
      </div>
      <div class="ability-introduce">
        <!-- 老师能力图谱描述 -->
        <p v-if="abilityRole == 1">
          参与本次项目的2020年第一期教师能力可获得包括理解分析学生能力、教学过程设计能力、教学策略选择能力、
          课程资源开发能力、课程资源利用能力、
          口语表达能力、管理指导学生能力、学习研究能力、创新能力的9种核心能力。</p>
        <!-- 学生能力图谱描述 -->
        <p v-else>
          参与本次项目的2020年第一期学生综合素质能力可获得包括思想品德、学业水平、身心健康、艺术素养、实践创新、校本特色的6种核心能力。</p>
      </div>
    </div>
    <!-- 无内容-->
    <Empty :show=" abilityRaderData && abilityRaderData.length == 0"></Empty>
  </div>

</template>

<script>
  export default {
    props: {
      abilityRole: {
        type: Number
      },
      abilityMealID: {
        type: Number
      },
      projectID: {
        type: Number
      },
    },
    data() {
      return {
        abilityRaderData: [], //能力图谱雷达图
        abilityRaderValueList: [], //能力图谱雷达图对应alue
      }
    },
    watch: {
      abilityRole(newVal, oldVal) {
        if (!newVal) return;
        this.getAbilityRadar();
      },
      abilityMealID(newVal, oldVal) {
        if (!newVal) return;
        this.getAbilityRadar();
      },
    },
    methods: {
      // 获取能力图谱数据
      async getAbilityRadar() {
        let params = {
          projectId: this.projectID,
          type: this.abilityRole,
          suitId: this.abilityMealID
        }
        for (const key in params) {
          if (!params[key]) return;
        }
        let resData = await this.$Api.Home.getAbilityRadar(params);
        // console.log(resData)
        this.abilityRaderData = []
        this.abilityRaderValueList = []
        if (!resData.data) return;
        this.abilityRaderData = resData.data;
        if (this.abilityRaderData.length) {
          this.abilityRaderData.map(item => {
            this.abilityRaderValueList.push(item.value);
          })
          this.abilityRaderData.map(item => {
            item.max = Math.max.apply(null, this.abilityRaderValueList) * 1.2
          })
        }

        if (this.abilityRaderData.length) this.drawRadar();
      },
      drawRadar() {
        let myChartCustom = this.$echarts.init(
          document.getElementById(`myChart-Custom-${this.projectID}`)
        );
        window.addEventListener('resize', () => {
          myChartCustom.resize()
        })

        myChartCustom.setOption({
          legend: {
            data: ['能力评价分', '能力最高分']
          },
          tooltip: {
            trigger: 'item',
          },
          // radar: [
          // {
          //     splitArea: {
          //         areaStyle: {
          //             color: ['rgba(114, 172, 209, 0.2)',
          //                 'rgba(114, 172, 209, 0.4)', 'rgba(114, 172, 209, 0.6)',
          //                 'rgba(114, 172, 209, 0.8)', 'rgba(114, 172, 209, 1)'
          //             ],
          //             // color:['rgba(167, 209, 255, 1)','rgba(255, 255, 255, 1)'],
          //             shadowColor: 'rgba(0, 0, 0, 0.3)',
          //             shadowBlur: 10
          //         }
          //     },
          //     axisLine: {
          //         lineStyle: {
          //             color: 'rgba(255, 255, 255, 0.5)'
          //         }
          //     },
          //     splitLine: {
          //         lineStyle: {
          //             color: 'rgba(255, 255, 255, 0.5)'
          //         }
          //     }
          // }, {
          //     indicator: this.abilityRaderData,
          //     center: ['50%', '50%'],
          //     radius: 120
          // }
          // ],
          radar: {
            indicator: this.abilityRaderData,
            // indicator: [{
            //   name: "sales",
            //   max: 6500
            // }, {
            //   name: "Administration",
            //   max: 16000
            // }, {
            //   name: "Information Techology",
            //   max: 30000
            // }, {
            //   name: "Customer Support",
            //   max: 38000
            // }, {
            //   name: "Development",
            //   max: 52000
            // }, {
            //   name: "Marketing",
            //   max: 25000
            // }],
            center: ['50%', '50%'],
            radius: 100,
            nameGap: 10,
            name: {
              show: true,
              color: "#508EF9",
              // backgroundColor: 'rgba(35, 115, 236, .1)',
              borderRadius: 2,
              padding: [3, 7, 3, 7],
              fontSize: 12,
              formatter: function (text, indicator) {
                // console.log(indicator);
                var strlength = text.length;
                if (strlength % 4 == 2) {
                  text = text.replace(/\S{4}/g, function (match) {
                    return match + '\n'
                  })
                } else {
                  text = text.replace(/\S{4}/g, function (match) {
                    // console.log(match);
                    return match + '\n'
                  })
                  strlength = text.length;
                  text = text.substring(0, strlength - 1);
                }
                return text
              },

            },
            splitArea: {
              areaStyle: {
                color: ['rgba(255, 255, 255, 1)', 'rgba(235, 244, 255, 1)'],
              }
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(167, 209, 255, 1)'
              }
            },
            splitLine: {
              lineStyle: {
                width: 2,
                color: 'rgba(210, 232, 255, 1)'
              }
            }
          },
          series: [{
            name: '能力图谱',
            type: 'radar',
            symbol: 'none',
            // radarIndex: 1,
            data: [{
              value: this.abilityRaderValueList,
              areaStyle: {
                opacity: 0.6,
                // color: "#A6A9E3"
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0,
                    color: 'rgba(254, 82, 119, 0.6)' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: 'rgba(253, 123, 151, 0.6)' // 100% 处的颜色
                  }],
                }
              },
              lineStyle: {
                width: 1,
                opacity: 0.6,
                color: "#FF0000"
              }
            }, ]
          }]
        })

      }
    },
    mounted() {
      this.getAbilityRadar();
    },
  }
</script>

<style lang="less" scoped>
  .ability-radar {
    margin-top: 15px;
    .chart-box {
      .ability-introduce {
        text-indent: 2em;
        margin-left: 5px;
        line-height: 22px;
        font-size: 12px;
        color: #666;
      }
    }

    .myChart-canvas-box {
      width: 301px;
      height: 320px;
      margin: 0 auto;
    }

    @media screen and (min-width: 1250px) {
      .myChart-canvas-box {
        width: 372px;
        height: 300px;
      }
    }

  }
</style>