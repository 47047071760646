<template>
  <div class="pie-conatiner">
    <div class="pie" v-for="(item,index) in dataMap" :key="index" :id="`myChart-${item.title}`">
      <!-- :style="{ width: '25%', height: '237px', paddingTop: '46px', paddingBottom: '41px' }" -->
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dataMap: {
          'course': {
            name: '课程数量',
            title: 'pieTotalCourse'
          },
          'work': {
            name: '作业平均提交率',
            title: 'pieHomeWork'
          },
          'results': {
            name: '学员成果数量',
            title: 'pieFile'
          },
          'team': {
            name: '专家团队',
            title: 'pieExpert'
          }
        },
        pieInfo: {},
        eachPieInfo: {},
        legendWidth: '80%'
      }
    },
    methods: {
      // 获取饼图数据 
      async getPieData() {
        let resData = await this.$Api.Home.getPieData();
        if (!resData.data) return;
        let info = resData.data;
        this.pieInfo = resData.data
        // console.log(resData);

        for (const key in info) {
          this.eachPieInfo[key] = [];
          // 课程数量 、 专家团队
          if (key === 'pieTotalCourse' || key === 'pieExpert') {

            for (const item in info[key]) {
              if (item == 'total') continue
              this.eachPieInfo[key].push({
                name: item,
                value: info[key][item]
              })
            }
          }
          // 学员成果数量
          if (key === 'pieFile') {
            let map = {
              video: [
                "baiwmv",
                "duasf",
                "asx",
                "rm",
                "rmvb",
                "mp4",
                "3gp",
                "mov",
                "m4v",
                "avi",
                "dat",
                "mkv",
                "flv",
                "vob",
                "3gpp"
              ],
              audio: ["wav", "mp3", "wma", "vqf", "avi", "mov", "wmv", "aac", "m4a"],
              img: ["bmp", "gif", "png", "jpg", "jpeg"],
              text: ["doc", "docx", "ppt", "pptx", "xls", "xlsx", "pdf", "txt", "wps"]
            }
            let count = {
              text: 0,
              audio: 0,
              img: 0,
              video: 0
            }
            let nameMap = {
              text: '文本',
              audio: '音频',
              img: '图片',
              video: '视频'
            }
            for (const mapKey in map) {
              for (const pieFileKey in info.pieFile) {
                if (map[mapKey].indexOf(pieFileKey) != -1) {
                  count[mapKey] += info.pieFile[pieFileKey]
                }
              }
            }
            this.pieInfo['pieFile'].total = 0;
            for (const countKey in count) {
              this.eachPieInfo.pieFile.push({
                value: count[countKey],
                name: nameMap[countKey]
              })
              this.pieInfo['pieFile'].total += count[countKey]
            }
            // console.log(count);

          }
          // 作业平均提交率
          if (key === 'pieHomeWork') {
            let map = {
              betweenFiftyAndEighty: '50%≤提交率≤80%',
              gtEighty: '提交率＞80%',
              leFifty: '提交率＜ 50 %'
            }
            for (const item in info[key]) {
              this.eachPieInfo[key].push({
                name: map[item],
                value: info[key][item]
              })
            }
            // console.log(this.eachPieInfo[key]);
          }
        }



        // console.log(this.eachPieInfo)
        this.drawPie();
      },
      // 绘制饼图
      drawPie() {
        let total = 0;
        let legendWidth = document.body.clientWidth <= 1250 ? '95%' : '80%';
        for (const item in this.dataMap) {
          let myChart = this.$echarts.init(
            document.getElementById(`myChart-${this.dataMap[item].title}`)
          );
          // 大小自适应
          window.addEventListener('resize', () => {
            myChart.resize()
            let legendWidth = document.body.clientWidth <= 1250 ? '95%' : '80%';
            myChart.setOption({
              legend: {
                width: legendWidth,
              },
            });
          })
          // 饼图初始化
          myChart.setOption(this.defaultSepUp(this.dataMap[item].name));

          // 作业平均提交率没有总数
          if (this.dataMap[item].title === 'pieHomeWork') {
            total = '';
          } else {
            total = this.pieInfo[this.dataMap[item].title] ? this.pieInfo[this.dataMap[item].title].total : '';
          }
          // 如果存在饼图数据，则再次用正确数据渲染饼图
          if (this.eachPieInfo[this.dataMap[item].title]) {
            // 如果饼图数据为空则不渲染 进行下一个饼图渲染
            if (this.eachPieInfo[this.dataMap[item].title].length <= 0) continue;
            myChart.setOption({
              title: {
                text: `${this.dataMap[item].name} ${total}`
              },
              color: ['#FF6C60', '#57C8F2', '#F8D448', '#57F2A4', '#D357F2', '#4882F8', ],
              legend: {
                data: this.eachPieInfo[this.dataMap[item].title],
                width: legendWidth,
              },
              series: [{
                data: this.eachPieInfo[this.dataMap[item].title]
              }]
            });
          }
        }
      },
      // 饼图 默认初始化方法
      defaultSepUp(name) {
        let legendType = name === '专家团队' ? 'scroll' : 'plain';
        const defaultOption = {
          title: {
            text: `${name}`,
            left: 'center',
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },
          legend: {
            data: [],
            bottom: 0,
            width: '100%',
            type: legendType,
            itemGap: 10,
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              fontSize: 12
            },
          },
          color: ['#a6a6a6'],
          series: [{
            name: name,
            type: 'pie',
            radius: ['20%', '52%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 0,
              name: ''
            }]
          }]
        }
        return defaultOption
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.getPieData();
      })
    }

  }
</script>

<style lang="less" scoped>
  .pie-conatiner {
    margin-bottom: 26px;
    height: 317px;
    background: #ffffff;
    // box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.08);
    border-radius: 8px;

    .pie {
      display: inline-block;
      position: relative;
      width: 25%;
      height: 237px;
      padding-top: 46px;
      padding-bottom: 41px;

      &::after {
        position: absolute;
        content: ' ';
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 121px;
        background: rgba(223, 223, 223, 0.54);
      }

      &:last-child::after {
        display: none;
      }
    }
  }
</style>